@font-face {
    font-family: 'SKINNY';
    src: url('./assets/font/SKINNY.TTF'); /* IE9 Compat Modes */
}

@font-face {
    font-family: 'ZX';
    src: url('./assets/font/ZX_SPECTRUM-7.TTF'); /* IE9 Compat Modes */
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: 'ZX', sans-serif;
    letter-spacing: -2px;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

html, body {
    touch-action: none
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
    display: none;

}

body {
    background: #000;
    background-position-y: -100px;
    font-weight: 400;

}

#root {
    /*overflow:scroll;*/
}

#bg {

    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    /*background-position: 0 0;*/
}

ol, ul {
    list-style: none;

    font-weight: normal;
}

li {
    font-weight: normal;

}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

